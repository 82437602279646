import { camelCase, size } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { AppState } from '../../../store';
import { BULKY_CANCELLATION_TIME_FRAMES } from 'src/common/constants';
import { BulkyItemScheduler } from 'src/vendors/interfaces/BulkyItemScheduler';
import { createErrorNotification, createSuccessNotification } from 'src/core/services/createNotification';
import { currentVendorId } from '../../services/currentVendorSelector';
import { DateType } from '../forms/BulkyItemSeasonalPickupScheduleAndBlackoutDatesSection';
import { EXACTLY_ID, PERIOD_TYPES_MAPPING, WITHIN_ID } from 'src/vendors/constants/bulkyItemScheduler';
import { Modal } from '../../../core/components';
import { ModalFixedHeader, ModalTitle, ModalClose, ModalCloseIcon } from '../../../core/components/styled';
import { ModalProps } from '../../interfaces/ModalProps';
import { saveBulkyItemScheduler } from 'src/vendors/ducks';
import { scrollToTopOfModal } from 'src/common/hooks/scroll';
import { timeFormatWithoutSeconds } from 'src/utils/services/validator';
import BulkyItemSchedulerForm from '../forms/BulkyItemSchedulerForm';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import translate from '../../../core/services/translate';

export const bulkyItemSchedulerModalId = 'bulky-item-scheduler-modal-id';

interface Props extends ModalProps {
  closeModal: (pristine?: boolean) => void;
}

export default function BulkyItemSchedulerModal({ closeModal }: Props) {
  const dispatch = useDispatch();

  const vendorId = useSelector(currentVendorId);
  const { bulkyItemScheduler, isLoading, isSaving } = useSelector(
    (state: AppState) => state.vendors.bulkyItemScheduler,
  );

  const seasonalDateTypes = bulkyItemScheduler.bulkySchedulerSeasonalPickUpSettings?.map(setting => {
    return !!setting.seasonalPickUpSettings?.singleDate
      ? DateType.single
      : !!setting.seasonalPickUpSettings?.specificBeginDate
      ? DateType.specific
      : DateType.descriptive;
  });

  const blackoutDateTypes = bulkyItemScheduler.bulkySchedulerBlackoutSettings?.map(setting => {
    return !!setting.blackoutSettings?.singleDate
      ? DateType.single
      : !!setting.blackoutSettings?.specificBeginDate
      ? DateType.specific
      : DateType.descriptive;
  });

  const initialValues = {
    ...bulkyItemScheduler,
    cancellationTimeFrameTypeId: bulkyItemScheduler?.cancellationTimeFrameType?.id,
    bulkySchedulerLimitByDaySettings: bulkyItemScheduler?.bulkySchedulerLimitByDaySettings?.length
      ? bulkyItemScheduler?.bulkySchedulerLimitByDaySettings?.map(setting => {
          return {
            ...setting,
            geoFences: setting?.geoFences?.map(geoFence => {
              return geoFence?.geoFenceId.toString();
            }),
          };
        })
      : [],
    bulkySchedulerLimitByTimeRangeSettings: bulkyItemScheduler?.bulkySchedulerLimitByTimeRangeSettings?.length
      ? bulkyItemScheduler?.bulkySchedulerLimitByTimeRangeSettings?.map(setting => {
          return {
            ...setting,
            geoFences: setting?.geoFences?.map(geoFence => {
              return geoFence?.geoFenceId.toString();
            }),
          };
        })
      : [],
    blackoutDateTypes,
    seasonalDateTypes,
    isZoneLimitByTimeRange:
      !bulkyItemScheduler.isZoneLimitByDayOfWeek && !bulkyItemScheduler.isZoneLimitByTimeRange
        ? true
        : bulkyItemScheduler.isZoneLimitByTimeRange,
    materialPickupSettings:
      bulkyItemScheduler?.materialPickupSettings?.map(materialPickupSetting => {
        return {
          ...materialPickupSetting,
          subsetItems:
            materialPickupSetting?.subsetItems?.map(subsetItem => {
              const { startFrom, endDate } = subsetItem;

              return {
                ...subsetItem,
                startFrom: startFrom ? moment(startFrom) : undefined,
                endDate: endDate ? moment(endDate) : undefined,
              };
            }) || [],
        };
      }) || [],
    bulkySchedulerSeasonalPickUpSettings: bulkyItemScheduler?.bulkySchedulerSeasonalPickUpSettings?.map(
      bulkySchedulerSeasonalPickUpSetting => {
        const { specificBeginDate, specificEndDate } = bulkySchedulerSeasonalPickUpSetting.seasonalPickUpSettings;

        return {
          ...bulkySchedulerSeasonalPickUpSetting,
          seasonalPickUpSettings: {
            ...bulkySchedulerSeasonalPickUpSetting.seasonalPickUpSettings,
            specificBeginDate: specificBeginDate ? moment(specificBeginDate) : undefined,
            specificEndDate: specificEndDate ? moment(specificEndDate) : undefined,
          },
        };
      },
    ),
    bulkySchedulerBlackoutSettings: bulkyItemScheduler?.bulkySchedulerBlackoutSettings?.map(
      bulkySchedulerBlackoutSetting => {
        const { specificBeginDate, specificEndDate } = bulkySchedulerBlackoutSetting.blackoutSettings;

        return {
          ...bulkySchedulerBlackoutSetting,
          blackoutSettings: {
            ...bulkySchedulerBlackoutSetting.blackoutSettings,
            specificBeginDate: specificBeginDate ? moment(specificBeginDate) : undefined,
            specificEndDate: specificEndDate ? moment(specificEndDate) : undefined,
          },
        };
      },
    ),
    recurringPriorDaysOffset: bulkyItemScheduler?.isEnabledMinusDaysOffset ? WITHIN_ID : EXACTLY_ID,
    recurringAfterDaysOffset: bulkyItemScheduler?.isEnabledPlusDaysOffset ? WITHIN_ID : EXACTLY_ID,
    isEnabledPriorDaysOffset:
      bulkyItemScheduler?.isEnabledMinusDaysOffset || bulkyItemScheduler?.isEnabledMinusExactDaysOffset,
    isEnabledAfterDaysOffset:
      bulkyItemScheduler?.isEnabledPlusDaysOffset || bulkyItemScheduler?.isEnabledPlusExactDaysOffset,
  };

  const saveConfiguration = (formData: BulkyItemScheduler) => {
    const formDataFormatted = {
      ...formData,
      cancellationTimeFrameType: BULKY_CANCELLATION_TIME_FRAMES[formData?.cancellationTimeFrameTypeId],

      bulkySchedulerLimitByDaySettings: formData?.bulkySchedulerLimitByDaySettings?.map(setting => {
        return {
          ...setting,
          pickUpWindowBegin: !!setting?.pickUpWindowBegin
            ? moment(setting?.pickUpWindowBegin, timeFormatWithoutSeconds).format('HH:mm:ss')
            : null,
          pickUpWindowEnd: !!setting?.pickUpWindowEnd
            ? moment(setting?.pickUpWindowEnd, timeFormatWithoutSeconds).format('HH:mm:ss')
            : null,
          geoFences: setting?.geoFences?.map(geoFence => {
            return {
              geoFenceId: parseInt(geoFence as unknown as string),
            };
          }),
        };
      }),
      bulkySchedulerLimitByTimeRangeSettings: formData?.bulkySchedulerLimitByTimeRangeSettings?.map(setting => {
        return {
          ...setting,
          pickUpWindowBegin: !!setting?.pickUpWindowBegin
            ? moment(setting?.pickUpWindowBegin, timeFormatWithoutSeconds).format('HH:mm:ss')
            : null,
          pickUpWindowEnd: !!setting?.pickUpWindowEnd
            ? moment(setting?.pickUpWindowEnd, timeFormatWithoutSeconds).format('HH:mm:ss')
            : null,
          period: setting && setting.period ? PERIOD_TYPES_MAPPING[setting?.period?.id] : undefined,
          geoFences: setting?.geoFences?.map(geoFence => {
            return {
              geoFenceId: parseInt(geoFence as unknown as string),
            };
          }),
        };
      }),
      materialPickupSettings: formData?.materialPickupSettings?.map(setting => {
        return {
          ...setting,
          categorySettings: {
            ...setting.categorySettings,
            price:
              !!setting.categorySettings.isEnabled &&
              (!setting.categorySettings.price || setting.categorySettings.price.toString() === '')
                ? 0
                : setting.categorySettings.price,
          },
          subsetItems: setting?.subsetItems?.map(subsetItem => {
            return {
              ...subsetItem,
              price:
                !!subsetItem.isEnabled && (!subsetItem.price || subsetItem.price.toString() === '')
                  ? 0
                  : subsetItem.price,
            };
          }),
        };
      }),
      isEnabledPlusDaysOffset:
        !formData?.isEnabledAfterDaysOffset || formData?.recurringAfterDaysOffset !== WITHIN_ID ? false : true,
      isEnabledPlusExactDaysOffset:
        !formData?.isEnabledAfterDaysOffset || formData?.recurringAfterDaysOffset !== EXACTLY_ID ? false : true,
      isEnabledMinusDaysOffset:
        !formData?.isEnabledPriorDaysOffset || formData?.recurringPriorDaysOffset !== WITHIN_ID ? false : true,
      isEnabledMinusExactDaysOffset:
        !formData?.isEnabledPriorDaysOffset || formData?.recurringPriorDaysOffset !== EXACTLY_ID ? false : true,
    };

    const bulkySchedulerLimitByDaySettingsFormatted = formDataFormatted?.bulkySchedulerLimitByDaySettings?.filter(
      setting => !!setting?.days?.length && (!!setting.maxPickUps || Number(setting.maxPickUps) === 0),
    );

    const bulkySchedulerLimitByTimeRangeSettingsFormatted =
      formDataFormatted?.bulkySchedulerLimitByTimeRangeSettings?.filter(
        setting => !!setting.period && (!!setting.maxPickUps || Number(setting.maxPickUps) === 0),
      );

    scrollToTopOfModal(bulkyItemSchedulerModalId);

    saveBulkyItemScheduler(
      {
        ...formDataFormatted,
        bulkySchedulerLimitByDaySettings: bulkySchedulerLimitByDaySettingsFormatted,
        bulkySchedulerLimitByTimeRangeSettings: bulkySchedulerLimitByTimeRangeSettingsFormatted,
      },
      vendorId,
    )(dispatch)
      .then(() => {
        closeModal();
        createSuccessNotification(translate('vendors.alertMessages.bulkyItemSchedulerSaved'));
      })
      .catch(error => {
        const { data } = error.response;
        const errorCode = data ? data.code : null;

        createErrorNotification(
          errorCode
            ? translate(`vendors.alertMessages.bulkyItemSchedulerError.${camelCase(errorCode)}`)
            : translate('vendors.alertMessages.bulkyItemSchedulerSaveError'),
        );
      });
  };

  return (
    <Modal
      isLoading={isLoading || isSaving}
      padding="no"
      size="xLarge"
      id={bulkyItemSchedulerModalId}
      overflow={isLoading || isSaving ? 'hidden' : 'auto'}
    >
      <ModalFixedHeader padding="medium no no no">
        <ModalClose onClick={() => closeModal()}>
          <ModalCloseIcon />
        </ModalClose>
        <ModalTitle>{translate(createTranslationKey('bulkyItemScheduler', 'vendors.featureCodes'))}</ModalTitle>
      </ModalFixedHeader>

      {!!size(bulkyItemScheduler) && (
        <BulkyItemSchedulerForm initialValues={initialValues} onSubmit={saveConfiguration} closeModal={closeModal} />
      )}
    </Modal>
  );
}
